//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import PaymentCheckout from "./PaymentCheckout";
export default {
  name: "Checkout",
  components: {
    PaymentCheckout
  },
  data: () => ({
    prefillService: {},
    stepperItems: [{
      number: 1,
      title: "Select Location and Test Category",
      active: false
    }, {
      number: 2,
      title: "Select Service",
      active: false
    }, {
      number: 3,
      title: "Checkout & Payment",
      active: true
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: false
    }]
  }),
  computed: {
    ...mapState({
      dataService: state => state.testRequest.products
    })
  },
  async mounted() {
    if (!this.$route.params.id && !Object.values(this.dataService).length) {
      this.$router.push({
        name: "customer-request-test"
      });
    }
  },
  methods: {
    handleBack() {
      if (!this.$route.params.id) return;
      this.$router.push({
        name: "customer-request-test-service"
      });
    }
  }
};